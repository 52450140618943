.height-100vh {
  height: 100vh;
}
.link-ico-control {
  padding: 5px;
}
.react-datepicker__triangle {
  display: none
}

.react-datepicker-popper {
  z-index: 999;
}

.counterparty-create {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  font-size: 0.875rem;
  text-decoration: none;
  padding-right: calc(var(--#{$variable-prefix}gutter-x) * .5);
}

.react-confirm-alert-overlay {
  z-index: 9999;
}

.container-wrapper {
  background: $white;
  padding: 26px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.text-right {
  text-align: right;
}

a {
  color: #0089EF;
  font-size: 16px;
  line-height: 19px;
}

.table {
  display: table;
}

.cell {
  display: table-cell;
}

.main-layout {
  height: 100%;
  margin-bottom: 0;
}

.authorized-layout {
  background: $white;
  vert-align: top;
  .container-wrapper {
    background: $white;
    padding: 0px;
    box-shadow: none;
  }
  .form-inline {
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
    }
    .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }
}


.unauthorized-layout {
  background: $gray-200;
  vertical-align: middle;
}


.navbar.bg-primary {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.icon {
  display: table-cell;
  height: 25px;
  width: 25px;
  min-width: 25px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.storage {
  @include icon-storage('white');
}

.calc {
  @include icon-calc('white');
}
.cashbox {
  @include icon-cashbox('white');
}
.document {
  @include icon-storage('white');
}
.help {
  @include icon-help('white');
}
.indicator {
  @include icon-indicator('white');
}
.pricelist {
  @include icon-pricelist('white');
}
.report {
  @include icon-report('white');
}
.supplier {
  @include icon-supplier('white');
}
.user {
  width: 28px;
  height: 28px;
  background-image: url("/assets/images/icons/icon-user.svg");
}

.navbar {
  .dropdown-menu {
    width: 14.51rem;
  }
  .icon.user{
    margin-right: -15px;
  }
  .user-dropdown  {
    margin-right: 180px;
  }
  .dropdown-item {
    font-size: 15px !important;
    line-height: 18px !important;
  }
  .container {
    max-width: 100%;
    .accordion {
      margin-bottom: 0.75rem;
      .accordion-item {
        border: none;
        color: $black;
        background: none;
        .accordion-button {
          &:not(.collapsed) {
            &:after {
              position: relative;
              margin-left: auto;
              left: -10px
            }
          }
          &:after {
            width: 20px;
            height: 10px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            background-size: contain;
          }
          color: $black;
          background: none !important;
          box-shadow: none;
          padding: 0;
          padding-left: 1rem;
          font-size: 15px;
          line-height: 18px;
          white-space: nowrap;
        }
        .accordion-body {
          padding: 0;
          padding-top: 5px;
          .nav-link {
            &:hover {
              color: black;
              background-color: #F9F9F9;
            }
            color: $black;
            padding: 0.25rem 1rem;
            font-size: 15px;
            line-height: 18px;
          }
        }

      }
    }
  }
}

.btn.btn-link {
  color: #0089EF;
  text-decoration: none;
}

.main-menu {
  &.active {
    width:350px;
    .menu-block {
      width: 350px;
    }
    .table {
      max-width: 350px;
      overflow-x: hidden;
    }
    .slider {
      span {
        background-image: url("/assets/images/arrow-left.svg");
      }
    }
    .text {
      display: table-cell;
    }
    .accordion-header {
      .accordion-button {
        &:after {
          display: flex !important;
        }
      }
    }
    .accordion-body {
      display: block !important;
    }
  }
  .text {
    padding-left: 25px;
    text-transform: uppercase;
    display: none;
    color: $white;
    vertical-align: middle;
    font-size: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  background: $primary;
  width:80px;
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  vertical-align: top;
  .slider {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0;
    width: 100%;
    text-align: right;
    span {
      cursor: pointer;
      z-index: 9;
      margin-right: -15px;
      background: #C9C9C9;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background-image: url("/assets/images/arrow-right.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .nav-link {
    &.active {
      background-color: $white;
      color: $primary;
      .storage {
        @include icon-storage('%230D87EF');
      }
      .calc {
        @include icon-calc('%230D87EF');
      }
      .cashbox {
        @include icon-cashbox('%230D87EF');
      }
      .document {
        @include icon-storage('%230D87EF');
      }
      .help {
        @include icon-help('%230D87EF');
      }
      .indicator {
        @include icon-indicator('%230D87EF');
      }
      .pricelist {
        @include icon-pricelist('%230D87EF');
      }
      .report {
        @include icon-report('%230D87EF')
      }
      .supplier {
        @include icon-supplier('%230D87EF')
      }
    }
    color: $white;
    padding: 16px 20px;
    margin-bottom: 0;
  }
  .accordion {
    padding: 0;
    .accordion-item {
      .accordion-header {
        .accordion-button {
          &:after {
            display: none;
          }
        }
      }
      border: none;
      color: $white;
      background: none;
      .accordion-button {
        &:after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
        color: $white;
        background: none !important;
        box-shadow: none;
      }
    }

    .accordion-body {
      display: none;
    }
  }
  .menu-block {
    width: 80px;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: $gray;
    }
    &::-webkit-scrollbar-thumb {
      background: $blue-200;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $blue-400;
    }
  }
}

.organizationList {
  .MuiPaper-root {
    table {
      tbody {
        tr {
          td {
            &:nth-child(2) {
              color: $green;
            }
          }
        }
      }
    }
  }
}

.storageList {
  .MuiPaper-root {
    table {
      tbody {
        tr {
          td {
            &:nth-child(1) {
              color: $green;
            }
            &:last-child {
              width: 105px;
            }
          }
        }
      }
    }
  }
}
