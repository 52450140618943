.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 34px;
}
