.form-select {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), 
.was-validated .form-select:invalid:not([multiple])[size="1"], 
.form-select.is-invalid:not([multiple]):not([size]), 
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: $form-select-indicator-padding;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
