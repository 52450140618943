@import '../../../assets/styles/variables';

.footer {
  background: #222222;
  padding: 52px 0 29px 0;
  font-size: 14px;
  line-height: 20px;
  color: $white;
}

.copyright {  
  padding-top: 30px;
  color: $basic-600;
}
