.navbar {
  padding-top: 1rem;
  padding-bottom: 1rem;

  #dropdown-language {
    button.btn-primary {
      text-transform: capitalize;
      background-color: $primary !important;;
      border: none !important;
      box-shadow: none !important;;
    }
    .dropdown-menu {
      min-width: auto;
    }
  }

  .navbar-toggler {
    padding: 0;
    border: none;
    margin-right: 1.25rem!important;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      color: inherit;
    }

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  .navbar-nav {
    .nav-link {
      font-size: 14px;
      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
      }
    }
    .dropdown-menu {
      @include media-breakpoint-down(sm) {
        position: absolute;
        right: 0;
        left: auto;
      }
      .dropdown-item {
        font-size: 0.875rem;
        .bi {
          color: $basic-500;
          margin-right: 0.625rem;
        }

        &.active .bi,
        &:active .bi {
          color: $white
        }
      }
    }
  }

  .navbar-brand {
    padding: 0;
  }

  .translate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: $white;
    text-transform: uppercase;
    color: $primary;
    border-radius: 50%;
    margin: 0 0.5rem;
    cursor: pointer;

    &:hover, &:focus, &.active {
      color: $white;
      background: $green;
    }
  }
}

.offcanvas {

  &.offcanvas-start {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    @include media-breakpoint-up(sm) {
      width: 400px;
    }
  }

  .accordion {

    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }

    .accordion-item {
      border-radius: 0;
      border: none;
    }

    .accordion-button {
      font-size: 0.875rem;
      background: none;
      padding-left: 0;
      padding-right: 0;

      &:focus {
        box-shadow: inset 0 ($accordion-border-width * -1) 0 $accordion-border-color;
      }
    }
  }

  .nav {
    flex-direction: column;
    font-size: 0.875rem;

    .nav-item {
      padding-bottom: 15px;
      span {
        display: block;
        font-weight: 700;
        padding: 0.5rem 0;
      }
      .nav-link {
        margin-left: 15px;
      }
    }

    .nav-link {
      padding-left: 0;
      padding-right: 0;
      color: $dark;

      &:hover, &:focus {
        color: $blue;
      }
    }
  }
}
