@import './assets/styles/variables';

.mainNotAuth {
  display: flex;
  flex: 1 1 auto;
  padding: 60px 0;
}

.main {
  display: block;
  flex: 1 1 auto;
  padding: 20px;
}
