@each $key, $val in $theme-colors-hover {
  .btn-#{$key} {
    &:hover,
    &:focus {
      background: $val;
      border-color: $val;
    }
  }
}

@each $key, $val in $theme-colors-active {
  .btn-#{$key} {
    &:active {
      background: $val;
      border-color: $val;
    }
  }
}

.btn-primary {
  color: $white!important;
}
