.form-control {
  line-height: 1.25rem;
  border: 1px solid $gray-100;
  background-color: $gray-200;
  border-radius: 8px;
  color: $gray-500;
  font-size: 16px;
  &.is-invalid {
    background-image: none;
  }
}

.form-control:focus {
  border-color: $gray-100;
}

.form-control::placeholder {
  color: #CECECE;
  font-size: 16px;
}

.form-control.phone {
  width: auto;
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover,
input.form-control:-webkit-autofill:focus,
input.form-control:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px $gray-200 inset !important;
}

.input-group-text {
  line-height: 1.25rem;
}

.show-password-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 55%;
  right: 1.3125rem;
  cursor: pointer;
}

.invalid-feedback-position {
  position: absolute;
  bottom: 2px;
  left: 12px;
  font-size: 0.75rem;
}

.form-check-input[type="checkbox"], .form-check-input[type="checkbox"]:checked {
  border: 1px solid #C7C7C7;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;

}

.form-check-input[type="checkbox"]:checked {
  border: 1px solid #525252;
}

.form-check-input[type="checkbox"]:focus {
  -webkit-box-shadow: none;
}
