.loader {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: -100px;  
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid transparent;
  animation: rotating 2.5s infinite ease;
  -webkit-animation: rotating 2.5s infinite linear;
  z-index: 9999;
  opacity: 1;
  border-top-color: #0D87EF;

  &:after, &:before {
    content: "";
    position: absolute;
    border-radius: inherit;
    border: inherit;
  } 
 
  &:after {
    top: 5px;
    left: 5px;
    width: 180px;
    height: 180px;
    animation: rotating 2s infinite ease;
    -webkit-animation: rotating 2s infinite linear;
    border-top-color: #000000;
  }
  &:before {
    top: 15px;
    left: 15px;
    width: 160px;
    height: 160px;
    animation: rotating 1.5s infinite ease;
    -webkit-animation: rotating 1.5s infinite linear;
    border-top-color: #4BBA45;
  }
}

@keyframes rotating {
  0% {transform: rotate(0deg)}
  100% {transform: rotate(360deg)}
}

@-webkit-keyframes rotating {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}
img.loader-old{
  display: none;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  background-color: #FFFFFF;
  &::after{
    background: rgba(252, 252, 252, 0.5);
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    transition: none;
    .modal &{
      height: 100%;
    }
  }
}