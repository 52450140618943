.popover {
  border-color: #F8F8F8;
}

.popover-body {
  padding: 9px 20px;
  color: #525252;
  font-size: 11px;
  line-height: 14px;
  background-color: #F8F8F8;
}

.bs-popover-bottom > .popover-arrow::after {
  border-bottom-color: #F8F8F8;
}

.bs-popover-bottom > .popover-arrow::before {
  border-bottom-color: #F8F8F8;
}
