.heading {
  font-weight: 600;
  font-size: 28px;
  line-height:  28px;
  margin-bottom: 29px;
  text-transform: uppercase;
  text-align: center;
}

.headingBorder {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  border-bottom: 1px solid #CECECE;
  padding-bottom: 12px;
  margin-bottom: 30px;
}
