.login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sign-up {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.invalid-feedback-position {
  position: absolute;
  bottom: 2px;
  left: 12px;
}
