.breadcrumb {
  margin-bottom: 2.875rem;
  .breadcrumb-item {
    color: $basic-700;
    font-size: 0.875rem;
    a {
      color: $primary;
      font-size: 0.875rem;
      text-decoration: underline;
    }
  }
}
